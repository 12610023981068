import classes from '../Offers/Offers.module.scss'
import report_classes from '../Reports/Reports.module.scss'

import setting_icon from '../../assets/icons/setting_slider.svg'
import search_icon from '../../assets/icons/search.svg'

// packages
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import Sortable from "sortablejs";
import { ToastContainer, toast } from 'react-toastify'


// import { IoIosSettings } from "react-icons/io"
// import { BiSearch } from "react-icons/bi"
// import { BiSearch } from "react-icons/bi"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Tr, Td } from 'react-super-responsive-table'


import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import FilterWindow from '../Cards/GrayWindow/FilterWindow'

import { uiActions } from '../../store/ui-slice'
import { displayDevices, displayCountries, ReportHeaders, getStatusButton, displayAllDataOfTargetOffer, apiHandler } from '../../utils/helper_functions'

    


const Offers = () => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    
    // data store section
    const [Data, setData] = useState([])
    const [termsAgreed, setTermsAgreed] = useState(false)

    // const [headCode, setHeadCode] = useState("")
    // const [bodyCode, setBodyCode] = useState("")
    const tableRef = useRef(null)

    //sorting
    let filteredData = [...Data];
    const [sortBy, setSortBy] = useState(null)
    const [sortOrder, setSortOrder] = useState('asc')

    //search
    const [searchQuery, setSearchQuery] = useState("")
    const [query, setQuery] = useState('find')

    const [limit, setLimit] = useState(20)
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)


    
    const handlePageSizeChange = (e) => {
        const newLimit = parseInt(e.target.value)
        setLimit(newLimit)
        
        // Fetch data with the new limit
        //fetchOffersSearch(currentPage, newLimit, searchQuery)
    }
    
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query)
        setCurrentPage(1) 
        
        // fetchOffersSearch(1, limit, query)
    }
    
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    
    //settings  
    const [showSettings, setShowSettings] = useState(false)

    
    //table content
    const [columnVisibility, setColumnVisibility] = useState({
        Name: true,
        Payout: true,
        PriceFormat: true,        
        AllowedCountries: true,
        Vertical: true,
        Platform: true,
        AffiliateLink: true
    })
    const [offersTableHeaders, setOffersTableHeaders] = useState(['#', 'Name', 'Payout', 'Price Format', 'Allowed Countries', 'Vertical', 'Platform', 'Affiliate Link'])


    //drag and drop
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(Object.entries(columnVisibility))
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        const updatedColumnVisibility = Object.fromEntries(items)
        setColumnVisibility(updatedColumnVisibility)
    };

    useEffect(() => {
        // Initialize Sortable after data is loaded
        if (tableRef.current) {
            Sortable.create(tableRef.current, {
                animation: 150,
                onEnd: handleDragEnd
            })
        }
    }, [columnVisibility]) 

    const [pendingColumnVisibility, setPendingColumnVisibility] = useState({ ...columnVisibility })
    const handleCheckboxChange = (columnName) => {
        setPendingColumnVisibility(prevVisibility => ({
            ...prevVisibility,
            [columnName]: !prevVisibility[columnName]
        }))
    };

    const applyChanges = () => {
        setColumnVisibility({ ...pendingColumnVisibility })

        var temp_header = Object.keys(pendingColumnVisibility).filter(header => pendingColumnVisibility[header])
        setOffersTableHeaders(['#', ...temp_header])
        setShowSettings(false) // Close settings dropdown after applying changes
    };

    // const toggleColumnVisibility = (columnName) => {
    //     setColumnVisibility(prevVisibility => ({
    //         ...prevVisibility,
    //         [columnName]: !prevVisibility[columnName]
    //     }))
    // };

    const toggleSettings = (e) => {
       if (!e.target.closest(`.${classes.settings}`)) {
            setShowSettings(!showSettings)
        }
    }

    useEffect(() => {
        if (tableRef.current) {
            Sortable.create(tableRef.current, {
                animation: 150,
                onEnd: handleSortEnd
            })
        }
    }, [])


    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else {
            setSortBy(columnName)
            setSortOrder('asc')
        }
    }

    const [identifier, setIdentifier] = useState('')   
    const [identifierStatus, setIdentifierStatus] = useState('pending')
    const identifierChange = (e) => {
        let value = e.target.value
        if(value.length < 5) {
            setIdentifierStatus('pending')
        }else { 
            setIdentifierStatus('success')
        }
        setIdentifier(value)
    }

  


    useEffect(()=>{
        document.title = "LinkVantage | Offers"
        // Initialize Sortable after data is loaded
        if (tableRef.current) {
            Sortable.create(tableRef.current, {
                animation: 150,
                onEnd: handleSortEnd
            })
        }
    }, [])

    const addTrackerToUrl = (url, vertical=null) => {
        try{
            let urlObj = new URL(url)
            urlObj.searchParams.set("ref", vertical)
            return urlObj.toString()    
        }catch(ex){
            return url+"/?ref="+vertical
        }
    }

    
    const handleSortEnd = (event) => {
        // Reorder the Data state based on the new order of rows
        const newData = Array.from(Data)
        const [movedItem] = newData.splice(event.oldIndex, 1)
        newData.splice(event.newIndex, 0, movedItem)
        setData(newData)
    }

    // Apply search filter
    if (searchQuery) {
        filteredData = filteredData.filter(item => 
            // console.log("search item :", item)
            item.offer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.category.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }
    // Sort the filtered data based on the current sorting criteria
    if (sortBy) {
        filteredData.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        })
    }

    // Pagination 
     const [totalPages, setTotalPages] = useState(1)
    // const[page,setpageNumber] = useState(1)
    const totalPagesCount = Math.ceil(filteredData.length / limit)
    const totalItems = filteredData.length;

    useEffect(() => {
        const totalPagesCount = Math.ceil(filteredData.length / limit)
        // console.log("Filtered Data Length:", filteredData.length)
        // console.log("Total Pages Count:", totalPagesCount)
        setTotalPages(totalPagesCount)
    }, [filteredData, limit])

    const indexOfLastOffer = currentPage * limit;
    const indexOfFirstOffer = indexOfLastOffer - limit;
    const currentOffers = filteredData.slice(indexOfFirstOffer, indexOfLastOffer)


    const fetchOffers = async (page, limit, query) => {
        const response = await apiHandler(`/my_offers?limit=${limit}&page=${page}`, 'GET', null, setError)

        if(response){
            console.log('---------->>', response.offers)

            setData(response.offers)
            const totalOffers = response.totalCount || 0;
            setTotalCount(totalOffers)
            const totalPagesCount = Math.ceil(totalOffers / limit)
            setTotalPages(totalPagesCount)
        }

        console.log("Now error is :", error)
        setLoading(false)
    }



















        


    // const target_offer_id = localStorage.getItem('target_offer_id') || null
    // if(target_offer_id){
    //     console.log("target_offer_id is :", target_offer_id)
    //     setFromAdminUser(true)
    //     fetchTargetUserOffers(target_offer_id, currentPage, limit)
    //     localStorage.removeItem('target_offer_id')
    // }else{
    //     console.log("ELSE")

    useEffect(() => {
        fetchOffers(currentPage, limit, query)
    }, [currentPage, limit, query])


    //Affiliate Link
    const [unlockedOffers, setUnlockedOffers] = useState(new Set())
    const handleUnlock = (offerId) => {
        if (unlockedOffers.has(offerId)) {
            unlockedOffers.delete(offerId)
        } else {
            unlockedOffers.add(offerId)
        }
        setUnlockedOffers(new Set(unlockedOffers))
    };

    // Function to handle copying the link
    const handleCopyLink = (offerId) => {
        //console.log("Link copied for offer ID:", offerId)
    };

    // Function to check if an offer is unlocked
    const isOfferUnlocked = (offerId) => {
        return unlockedOffers.has(offerId)
    };


    //popup
    const [showPopUp, setShowPopUp] = useState(false)
    const [selectedOffer, setSelectedOffer] = useState(null)

    const handleOfferNameClick = (offer) => {
        setSelectedOffer(offer) // Set the selected offer details
        setShowPopUp(true) // Show the PopUpCard
    };










    const renderOffers = () => {
        let filteredData = [...Data];
        let offersData = currentOffers.map((item, index) => (
            <Tr key={index}>
                <>
                    <Td>
                        <div>{index+1}</div>
                    </Td>
                    {columnVisibility.Name && 
                        <Td className={classes.cursor_pointer} onClick={() => handleOfferNameClick(item)}>
                            <div>
                                {item.offer_name}
                            </div>
                        </Td>
                    }

                    {columnVisibility.Payout && <Td> <div>
                        {item.category === "CPA" && `$ ${item.payoutPer}`}
                        {item.category === "CPL" && `$ ${item.payoutPer}`}
                        {item.category === "Content Arbitrage" && `${item.payoutPer}%`}
                        {item.category === "Search" && `${item.payoutPer}%`}
                    </div></Td>}

                    {columnVisibility.PriceFormat && <Td>{item.category}</Td>}
                    {columnVisibility.AllowedCountries && 
                        <Td>
                            {displayCountries(item.countries)}
                        </Td>
                    }
                    {columnVisibility.Vertical && <Td>{item.vertical}</Td>}
                    {columnVisibility.Platform && 
                        <Td>
                            {displayDevices(item.devices)}
                        </Td>
                    }
                    {columnVisibility.AffiliateLink && (
                        <Td className={classes.cursor_pointer}>
                            {getStatusButton(item.offer_status, item, addTrackerToUrl, handleOfferNameClick)}
                        </Td>
                    )}
                </>
            </Tr>
        )
    )


    // Add a row for "No Data" if there are no offers
    if (filteredData.length === 0) {
        offersData.push(
            <Tr key={0}>
                <Td style={{textAlign:"center"}} colSpan={10}>No Data Found</Td>
            </Tr>
        )
    }
    return offersData;
    };





    const applyForOfferHandler = async (target_id) => {
        console.log("----------> apply for run for this offer :", target_id)

        if(!termsAgreed){
            toast.error("Please check 'I agree' and try again.")
            setError(true)
            return
        }

        setLoading(true)
        const response = await apiHandler('/applyforrun', 'POST', { offer: target_id }, setError)
        if(response){
            console.log('-------->', response.offers)
            setData(response.offers)
            dispatch(uiActions.setAwaitingOffersNumber(response.awaiting_approvals_len))

            toast.success("Successfully applied for the offer.")
            setSuccess(true)

            const totalOffers = response.totalCount || 0
            setTotalCount(totalOffers)
            const totalPagesCount = Math.ceil(totalOffers / limit)
            setTotalPages(totalPagesCount)

            setShowPopUp(false)
        }
        setLoading(false)
    }






   



    



    return (
        <> 
            {/* offers main component section */}
            <div className={classes.container}>
                {showPopUp && selectedOffer && (
                    <PopUpCard loading={loading} header={`${selectedOffer.offer_name}`} toggle={() => {
                            setShowPopUp(false)
                            setTermsAgreed(false)
                        }
                    }>
                        {displayAllDataOfTargetOffer(selectedOffer, loading, showPopUp, setShowPopUp, applyForOfferHandler, setTermsAgreed, false)}
                    </PopUpCard>
                )}

                <div className={classes.main}>
                    <div>
                        <div className={classes.maintop_container}>
                            <div className={classes.second_section}>    
                                <div className={classes.settings_main}>
                                    <div className={classes.settings_icon} onClick={toggleSettings}>
                                        {/* <IoIosSettings /> */}
                                        <img src={setting_icon} alt="settings"/>
                                        {showSettings && (
                                            <FilterWindow>
                                            <div className={classes.settings}>
                                                <div className={classes.maintop_settings }>
                                                    <div className={classes.maintop_settings_toplabel }>  
                                                        COLUMN                                                        
                                                    </div>
                                                    DISPLAY
                                                </div> 

                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <ul className={classes.settings_headernames}>
                                                        <Droppable droppableId="settingsList">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                >
                                                                    {Object.entries(columnVisibility).map(([header, isVisible], index) => (
                                                                        <Draggable key={header} draggableId={header} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <li
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                >
                                                                                    <div className={classes.settings_list}>
                                                                                        <label className={classes.settings_label} htmlFor={`${header}Checkbox`}>{header}</label>
                                                                                        <input
                                                                                            className={classes.settings_checkbox}
                                                                                            type="checkbox"
                                                                                            id={`${header}Checkbox`}
                                                                                            checked={pendingColumnVisibility[header]}
                                                                                            onChange={() => handleCheckboxChange(header)}
                                                                                            />
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </ul>
                                                </DragDropContext>
                                                <div className={classes.button_div}> 
                                                    <button className={classes.settings_applybutton} onClick={applyChanges}>Apply</button> 
                                                </div>
                                            </div>
                                            </FilterWindow>
                                        )}
                                    </div>   
                                </div>                               
                    
                                <div className={classes.search_icon}> 
                                    <img src={search_icon} alt="search" />
                                    <div className={classes.search}>
                                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch}/> 
                                    </div> 
                                </div>   

                                <div className={classes.entries}>
                                    Entries
                                </div>
                                <div className={classes.dropdown}>
                                    <select value={limit} onChange={handlePageSizeChange}>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                    </select>
                                </div>
                                {/* displayEntriesDiv = ( width, perPage, setPerPage, setPaginationStart, setPageNumber ) */}
                            </div>
                        </div>
                    </div> 

                    <div className={classes.main_middle}>
                        {success && <ToastContainer/>}
                        {error && <ToastContainer/>}


                        <ResponsiveTable loadingResults={loading}>
                            <thead><ReportHeaders header_names={offersTableHeaders}/></thead>
                            <tbody className={report_classes.table_data}>{renderOffers()}</tbody>
                        </ResponsiveTable>
                    </div>
                   
                    <div className={classes.pagination}>
                        <button type="button" className={classes.page_number} onClick={() => handlePageChange(1)}>«</button>
                        <button type="button" className={classes.page_number} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>‹</button>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                type="button"
                                className={`${classes.page_number} ${currentPage === index + 1 ? classes.active : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button type="button" className={classes.page_number} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>›</button>
                        <button type="button" className={classes.page_number} onClick={() => handlePageChange(totalPages)}>»</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Offers