import classes from './Reports.module.scss'
import tools_classes from '../Tools/Tools.module.scss'

import edit_user from '../../assets/icons/pencil.svg'
import eye_green from '../../assets/icons/eye_green.svg'
import delete_user from '../../assets/icons/trash_red.svg'
import tagsSolid from '../../assets/icons/tags-solid_blue.svg'
import userSolid from '../../assets/icons/user-solid_blue.svg'
import search_gray_icon from '../../assets/icons/search.svg'
import cross_gray_icon from '../../assets/icons/cross.svg'
import password_white from '../../assets/icons/password_white.svg'
import verify_png from '../../assets/icons/verify.svg'
import arrow_left from '../../assets/icons/arrow_left.svg'
import arrow_right from '../../assets/icons/arrow_right.svg'


import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

// third party packages/libraries
import { ToastContainer, toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { ImpulseSpinner } from "react-spinners-kit"
import { Tr, Td } from 'react-super-responsive-table'


// components
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import { offers_table_headers_limited, ReportHeaders, getStatusText, displayCountries, displayAllDataOfTargetOffer, handlePagination, displayEntriesDiv } from '../../utils/helper_functions'


// helper functions
// import { userActions } from '../../store/user-slice'
import { uiActions } from '../../store/ui-slice'
import useWindowSize from '../../assets/hooks/useWindowSize.js'





const AdminUsers = () => {
    const dispatch = useDispatch()
    let { width } = useWindowSize()

    // required variables and names
    // const date_options = useSelector(state => state.ui.language.values.date_options)

    const names = useSelector(state => state.ui.language.values.reportsContent)
    const report_table_headers = ["#", "Full Name", "Email", "State", "Balance", "Registered_at", "Verified_at","Last login", "Actions"]

    // Login As User
    // View Full Details - > All details mention above
    // Edit - > All details mention above > Reset Password
    // Delete
    // Offers Approval - > Redirect for the same user offers page

    const API_URL = process.env.REACT_APP_API_URL
    
    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)
    const [loadingResultsFailed, setLoadingResultsFailed] = useState(false)
    
    const [loadingSearchResults, setLoadingSearchResults] = useState(false)    
    const [popupLoading, setPopupLoading] = useState(false)
    

    
    // pagination section states
    const [totalRows, setTotalRows] = useState(0)
    const [rowsSent, setRowsSent] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [paginationMultiplyNum, setPaginationMultiplyNum] = useState(0)

    const [paginationStart, setPaginationStart] = useState(false)

    // toast alert states
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    // view/edit div section
    const [showUser, setShowUser] = useState(false)
    const [editUser, setEditUser] = useState(false)

    // search section states
    const [searchValue, setSearchValue] = useState('')

    // table section states
    const [Data, setData] = useState([])

    // const [showDateRange, setShowDateRange] = useState(false)
    // const [selectionRange, setSelectionRange] = useState({
    //     startDate: new Date(),
    //     endDate: new Date()
    // })

    // popup states
    const [showPopup, setShowPopup] = useState(false) 
    const [showResetPsPopup, setResetPsShowPopup] = useState(false) 

    // target user states
    const [targetUserId, setTargetUserId] = useState('')
    const [targetUserName, setTargetUserName] = useState('')

    // target user offer states 
    const [showOfferPopup, setShowOfferPopup] = useState(false)
    const [userOffersData, setUserOffersData] = useState([])

    const [selectedOffer, setSelectedOffer] = useState(null)
    const [displayOfferDataPopup, setDisplayOfferDataPopup] = useState(false)



    // toast states
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)








    // ################## edit user states ##################
    const [userId, setUserId] = useState("")
    const [profileName, setProfileName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [telegram, setTelegram] = useState("")
    const [skype, setSkype] = useState("")

    const [paymentRequested, setPaymentRequested] = useState("")
    const [balance, setBalance] = useState("")
    const [isAdmin, setIsAdmin] = useState("")

    const [taxId, setTaxId] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setCountryState] = useState("")
    const [country, setCountry] = useState("")
    const [zip, setZip] = useState("")

    // const [userStatus, setUserStatus] = useState(false)


    // reset password states
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")


    // popup error and success msg states
    // const [successMsg, setSuccessMsg] = useState("")
    // const [errorMsg, setErrorMsg] = useState("")



    const setThePaginationParameters = (rowsSentLength, totalRowsLength) => {
        // console.log("HERE _____", rowsSentLength, totalRowsLength)
        setRowsSent(rowsSentLength)
        setTotalRows(totalRowsLength)

        if(totalRowsLength < perPage){
            setTotalPages(1)
        }else{
            // console.log("Total pages are :", Math.ceil(totalRowsLength/perPage))
            setTotalPages(Math.ceil(totalRowsLength/perPage))
        }
    }


    const loginAsUser = async (user_id) => {
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/user_change`;

        let token = localStorage.getItem('access_token')
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token
            },
            data: {
                id: user_id
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response?.data?.access_token && ['ADMIN','MODERATOR', 'CUSTOMER'].includes(response?.data?.role)) {
                localStorage.setItem('access_token', response.data.access_token)
                localStorage.setItem('role', response.data.role)
                localStorage.setItem('name', response.data.name)
                localStorage.setItem('balance', response.data.balance)
                localStorage.setItem('last_payment_amount', response.data.balance)

                // console.log(response.data)
                // dispatch(userActions.setUser(email))
                // dispatch(userActions.setToken(response.access_token))
                // dispatch(userActions.setIsAdmin(['ADMIN'].includes(response.role)))
                // dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(response.role)))
                
                // dispatch(uiActions.setIsLoggedIn(true))
                // dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR'].includes(response.role)))
                // navigate('/', {replace: true })

                window.location.href = "/";
            }
            setLoadingResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    const getUsers = async (big_loading) => {
        if(big_loading){setLoadingResults(true)}else{setLoadingSearchResults(true)}

        let url = `${API_URL}/api/v1/admin-users`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token,
              'perpage': perPage,
              'pagenumber': pageNumber
            }
        }
        
        axios.request(config)
        .then((response) => {
            if(response.data.users){
                // console.log("=======>", response.data, response.data.total_results)
                setData(response.data.users)
                setThePaginationParameters(response.data.users.length, response.data.total_results)
                setPaginationStart(false)
            }
            if(big_loading){setLoadingResults(false)}else{setLoadingSearchResults(false)}
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                if(big_loading){setLoadingResults(false)}else{setLoadingSearchResults(false)}
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    useEffect(() => {
        document.title = "LinkVantage | Admin Users";
    },[])

    useEffect(()=>{
        if(searchValue!=="" && Data){
            handleSearchUser(searchValue)
        }else{
            getUsers(true)
        }
    }, [searchValue])


    useEffect(()=>{
        if(paginationStart){
            getUsers(false)
        }
    }, [perPage, pageNumber])

    useEffect(()=>{
        if(!popupLoading){
            if (pageNumber > 1) {
                setPaginationMultiplyNum((pageNumber-1)*perPage)
            }else{
                setPaginationMultiplyNum(0)
            }
        }
    }, [Data])


    const handleSearchUser = (search_query) => {
        console.log("User will be viewed is :", search_query)

        setLoadingSearchResults(true)
        let url = `${API_URL}/api/v1/search-users`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: { 
                query: searchValue,
                perpage: perPage,
                pagenumber: pageNumber,
            }
        }
        
        axios.request(config)
        .then((response) => {
            if(response.data.users){
                setData(response.data.users)
                setThePaginationParameters(response.data.users.length, response.data.total_results)
            }
            setLoadingSearchResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingSearchResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }


    const handleViewUser = (user_id, from_edit) => {
        // console.log("User will be viewed/edited is :", user_id)
        setLoadingSearchResults(true)

        let url = `${API_URL}/api/v1/user-data`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token,
              'user': user_id,
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.user){
                const target_user_data = response.data.user
                // console.log("====target_user_data===>", target_user_data)

                setUserId(target_user_data._id)
                setProfileName(target_user_data.name)
                setEmail(target_user_data.email)
                setPhone(target_user_data.phone)
                setTelegram(target_user_data.telegram)
                setSkype(target_user_data.skype)

                setPaymentRequested(target_user_data.payment_requested)
                setBalance(target_user_data.balance)
                setIsAdmin(target_user_data.is_admin)


                setTaxId(target_user_data.taxId)
                setAddress(target_user_data.address)
                setAddress2(target_user_data.address2)

                setCity(target_user_data.city)
                setCountryState(target_user_data.state)
                setCountry(target_user_data.country)
                setZip(target_user_data.zip)

                setShowUser(true)
                if(from_edit){
                    setEditUser(true)
                }
            }
            setLoadingSearchResults(false)
        })
        .catch((error) => {
            setShowUser(false)
            if(from_edit){setEditUser(false)}
            if(error && error.response && error.response.status > 400 ){
                setLoadingSearchResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }



    const handleDeleteUser = (user_id) => {       
        console.log("User will be deleted is :", user_id)
        setPopupLoading(true)
        let url = `${API_URL}/api/v1/delete-user`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: { 
                user: user_id,
                perpage: perPage,
                pagenumber: pageNumber,
            }
        }
        
        axios.request(config)
        .then((response) => {
            if(response.data.users){
                // console.log("====delete user===>", response.data)
                setData(response.data.users)
                setThePaginationParameters(response.data.users.length, response.data.total_results)

                setTimeout(()=>{
                    setShowPopup(false)
                }, 2000)
            }
            setPopupLoading(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setPopupLoading(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }


    const handleGoToOffer = (target_id, target_user_name) => {
        console.log("target user name is :", target_id, target_user_name)
        setTargetUserId(target_id)
        setTargetUserName(target_user_name)
        setShowOfferPopup(true)

        // const [showOfferPopup, setShowOfferPopup] = useState(false)
        // const [userOffersData, setUserOffersData] = useState([])
    
        // const [selectedOffer, setSelectedOffer] = useState(null)
        // const [displayOfferDataPopup, setDisplayOfferDataPopup] = useState(false)

    }

    const verifyUser = (target_id) => {
        // console.log("Changing status for :", target_id, target_status)

        setLoadingSearchResults(true)
        let url = `${API_URL}/api/v1/user-verify`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: { 
                user: target_id,
                perpage: perPage,
                pagenumber: pageNumber,
            }
        };
        
        axios.request(config)
        .then((response) => {
            
            setLoadingSearchResults(false)
            getUsers(false)

        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingSearchResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }




    const handleSwitchUserStatus = (target_id, target_status) => {
        // console.log("Changing status for :", target_id, target_status)

        setLoadingSearchResults(true)
        let url = `${API_URL}/api/v1/user-status`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: { 
                user: target_id,
                status: target_status,
                perpage: perPage,
                pagenumber: pageNumber,
             }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.users){
                setData(response.data.users)
                setThePaginationParameters(response.data.users.length, response.data.total_results)
            }
            setLoadingSearchResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingSearchResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    const renderReports = () =>{
        let reportsData = []
        Data.forEach((data, index) =>  {
            reportsData.push(
                <tr key={index}>
                    <td>{paginationMultiplyNum + (index+1)}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{data.country?(
                        <>
                            {displayCountries([data.country])}
                        </>
                    ):(
                        "-"
                    )}
                    </td> 
                    <td>$ {data.balance.toLocaleString()}</td>
                    <td>{data.user_registered_at? data.user_registered_at :"-"}</td>
                    <td>{data.email_verified_at? data.email_verified_at :"-"}</td>
                    <td>{data.last_login? data.last_login :"-"}</td>
                    <td className="td_15">
                        <div className={classes.icon_div}>
                            <Tooltip title="Login as user" placement="top" arrow>
                                <img onClick={()=>{loginAsUser(data._id)}} src={userSolid} alt="Login as user"/>
                            </Tooltip>
                            <Tooltip title="User offers" placement="top" arrow>
                                <img onClick={() => {handleGoToOffer(data._id, data.name)}} src={tagsSolid} alt="Offers"/>
                            </Tooltip>
                            <Tooltip title="View user" placement="top" arrow>
                                <img onClick={()=>{handleViewUser(data._id, false)}} src={eye_green} alt="View"/>
                            </Tooltip>
                            <Tooltip title="Edit user" placement="top" arrow>
                                <img onClick={()=>{handleViewUser(data._id, true)}} src={edit_user} alt="Edit"/>
                            </Tooltip>
                            <Tooltip title="Reset password" placement="top" arrow>
                                <img onClick={()=>{
                                    setResetPsShowPopup(true)
                                    setTargetUserId(data._id)
                                    setTargetUserName(data.name)
                                    }} src={password_white} alt="Reset PS"/>
                            </Tooltip>


                            <Tooltip title="Verify User" placement="top" arrow>
                                <img onClick={()=>{
                                    verifyUser(data._id)
                                }} src={verify_png} alt="Verify User"/>
                            </Tooltip>

                            <Tooltip title={data.status? "Block user":"Unblock user"} placement="top" arrow>
                                <label className={classes.checkbox}>
                                    <input type="checkbox" checked={data.status} onChange={()=>{handleSwitchUserStatus(data._id, !data.status)}}/>
                                    <span></span>
                                </label>
                            </Tooltip>
                            <Tooltip title="Delete user" placement="top" arrow>
                                <img onClick={()=>{
                                    setTargetUserId(data._id)
                                    setTargetUserName(data.name)
                                    setShowPopup(true)
                                }} src={delete_user} alt="Delete"/>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            )
        })
        if(Data.length===0){
            reportsData.push(<tr key={0}>
                <td style={{textAlign:"center"}} colSpan={6}>No Data</td>
            </tr>)
        }
        return reportsData
    }





    const handleEditUserData = async () => {
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        if(email.length === 0 || !(EMAIL_REGEX.test(email))){
            setIsError(true)
            toast.error("Invalid email")
            return
        }

        setLoadingSearchResults(true)
        let token = localStorage.getItem('access_token')
        const request_options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
            body: JSON.stringify({
                user: userId,
                name: profileName,
                email: email,
                phone: phone,
                telegram: telegram,
                skype: skype,

                balance: balance,
                payment_requested: paymentRequested,
                is_admin: isAdmin,

                taxId: taxId,
                address: address,
                address2: address2,
                city: city,
                state: state,
                country: country,
                zip: zip
            })
        }

        // console.log("request_options :", request_options, taxId)

        let response = null;
        try{
            response = await fetch(`${API_URL}/api/v1/update-profile`, request_options)
        }catch(ex){
            setIsError(true)
            setLoadingSearchResults(false)
            toast.error("Faild to fetch")
            return;
        }

        let res_code = response.status
        response = await response.json()
        // console.log("res is :", res_code, response)
        if(res_code >= 400) {
            setIsError(true)
            setLoadingSearchResults(false)
            toast.error("Unauthorized")
            return;
        }

        if(response.status){
            setIsSuccess(true)
            toast.success("User Updated")
            getUsers(false)
        }else{
            toast.error(response.message)    
        }
        setLoadingSearchResults(false)
    }




    // reset password function
    const handleResetPassword = async(target_id) => {
        // console.log("Resetting password for :", target_id, password, confirmPassword)

        if(password.length < 6){
            setIsError(true)
            toast.error("Password must be at least 6 characters long")
            return;
        }else if(password !== confirmPassword){
            setIsError(true)
            toast.error("Password not matched.")
            return;
        }

        setPopupLoading(true)
        let token = localStorage.getItem('access_token')
        const request_options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
            body: JSON.stringify({
                user: target_id,
                password: password
            })
        }

        let response = null;
        try{
            response = await fetch(`${API_URL}/api/v1/reset-user-password`, request_options)
        }catch(ex){
            setIsError(true)
            toast.error("Faild to fetch")
            setPopupLoading(false)
            return;
        }

        let res_code = response.status
        response = await response.json()
        // console.log("res is :", res_code, response)
        if(res_code >= 400) {
            setIsError(true)
            setPopupLoading(false)
            toast.error("Unauthorized")
            return;
        }

        if(res_code === 200){
            setIsSuccess(true)
            toast.success("Successfully password changed for the user.")

            setTimeout(() => {remove_ps_input()}, 6000)
        }else{
            setIsError(true)
            toast.error(response.message)    
        }
        setPopupLoading(false)
    }


    const remove_ps_input = () => {
        setPassword('')
        setConfirmPassword('')
        setResetPsShowPopup(false)
    }





    useEffect(()=> {
        if(showOfferPopup && targetUserId!==''){
            fetchTargetUserOffers(targetUserId, 1, 10)
        }
    }, [showOfferPopup])


    // ####################### target user limited offers section #######################
    const fetchTargetUserOffers =  async (target_id, page, limit) => {
        setLoadingResults(true)
        try {
            
            let access_token = localStorage.getItem('access_token')
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: `${API_URL}/api/v1/user-offers`,
                headers: {
                    'Authorization': 'Bearer '+access_token
                },
                data: {
                    user: target_id,
                    perpage: limit,
                    pagenumber: page,
                }
            }
    
            const response = await axios.request(config)    
            if (response.data.offers) {
                console.log("=== target user offers ==>", response.data.offers)
                setUserOffersData(response.data.offers)
            } else {
                setLoadingResultsFailed(true)
            }
        } catch (error) {
            console.log(error)
            setLoadingResultsFailed(true)
            if (error && error.response && error.response.status >= 400) {
                localStorage.removeItem('access_token')
                // window.location.href = "/login"
            }
        }
        setLoadingResults(false)
    }


    const handleChangeOfferStatusForUser = async (item, target_status) => {
        console.log("Changed offer status for user :", item, target_status)

        setLoadingResults(true)
        let token = localStorage.getItem('access_token')
        const request_options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                targetApprovalOffer: item['offer'],
                targetUser: item['user'],
                targetStatus: target_status,
                allData: true,
            })
        }

        let response = null;
        try{
            response = await fetch(`${API_URL}/api/v1/approval-status`, request_options)
        }catch(ex){
            setLoadingResults(false)
            return;
        }

        let res_code = response.status
        response = await response.json()
        if(res_code === 200 && response.approvals){
            console.log("Target offer target for approval is :", response.approvals)
            setUserOffersData(response.approvals)
            dispatch(uiActions.setAwaitingOffersNumber(response.awaiting_approvals_len))

            setLoadingResults(false)
            toast.success(`The status offer: ${item['offer_name']} for user: ${item['user_name']} is ${getStatusText(target_status)}`)
            setSuccess(true)

        }else{
            setLoadingResults(false)
            toast.error(`Offer: ${item['offer_name']} for ${item['user_name']} is approved`)
            setError(true)
        }
    }


    // const renderOffers = () => {
    //     let filteredData = [...Data];
    //     let offersData = offersData.map((item, index) => (
    //         <Tr key={index}>
    //             <>
    //                 <Td className={classes.cursor_pointer}>
    //                     <div>{index+1}</div>
    //                 </Td>
    //                 <Td className={classes.cursor_pointer} onClick={() => handleOfferNameClick(item)}>
    //                     <div>
    //                         {item.offer_name}
    //                     </div>
    //                 </Td>


    //                 <Td> <div>
    //                     {item.category !== "Content Arbitrage" && `$ ${item.payoutPer}`}
    //                     {item.category === "Content Arbitrage" && `${item.payoutPer}%`}
    //                 </div></Td>

    //                 <Td>{item.category}</Td>
    //                 <Td>
    //                     {displayCountries(item.countries)}
    //                 </Td>
                    
    //                 <Td>{item.vertical}</Td>

    //                 <Td className={classes.test}>
    //                     {displayDevices(item.devices)}
    //                 </Td>

    //                 <Td className={classes.cursor_pointer}>
    //                     {getStatusButton(item.offer_status, item, addTrackerToUrl, handleOfferNameClick)}
    //                 </Td>
    //             </>
    //         </Tr>
    //     ))
    // }

 



    const handleOfferNameClick = (offer) => {
        console.log("target offer item is :", offer)

        setSelectedOffer(offer) // Set the selected offer details
        setDisplayOfferDataPopup(true) // Show the PopUpCard
    }

    const renderLimitedOffers = () => {
        let offersData = userOffersData.map((item, index) => (
            <Tr>
                <Td>{index+1}</Td>
                <Td className={classes.cursor_pointer} onClick={() => handleOfferNameClick(item)}>
                    <div>{item.offer_name}</div>
                </Td>
            
                <Td>
                    <select
                        name='status'
                        onChange={(e)=>handleChangeOfferStatusForUser(item, e.target.value)}
                        style={{
                            borderStyle: "none",
                            outline: "none",
                            fontSize: "1em",
                            color: "#374957",
                            width: "100%",
                        }}>    

                        {item.offer_status==='public'?(
                            <>
                            <option key="Public" value="public" disabled selected={item.offer_status==='public'}>Public</option>
                            </>
                        ):(
                            <>
                            <option key="Apply To Run" value="apply" selected={item.offer_status==='apply'}>Apply To Run</option>
                            <option key="Pending" value="pending" selected={item.offer_status==='pending'}>Pending</option>
                            <option key="Active" value="active" selected={item.offer_status==='active'}>Active</option>
                            </>
                        )}
                    </select>
                </Td>
            </Tr>
        ))

        return offersData
    }


    return (
        <>
        <div className={classes.container}>
            {showPopup && (
                <PopUpCard toggle={()=>{setShowPopup(false)}} header={`Delete user: ${targetUserName}?`}>
                    <div className={classes.popup_content_div}>
                        <div className={classes.popup_content_main}>
                            Once you click <b>Delete,</b>the user will be removed forever.
                        </div>
                        <div className={classes.bottom_buttons}>
                            {popupLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                            <button onClick={()=>{setShowPopup(false)}} className={classes.cancel_btn}>Cancel</button>
                            <button onClick={()=>{handleDeleteUser(targetUserId)}} className={classes.apply_btn}>Delete</button> 
                        </div>
                    </div>                         
                </PopUpCard>
            )}

            {showResetPsPopup && (
                <PopUpCard toggle={()=>{remove_ps_input()}} header={`Reset Password for ${targetUserName}`}>
                    <div className={classes.popup_content_div}>
                        <div className={classes.popup_content_main_column}>
                            <div className={tools_classes.form_group}>
                                <div className={tools_classes.text_left}>New Password</div>
                                <input type="password" onChange={(e)=>setPassword(e.target.value)} value={password} id="ps" name='password' placeholder={password} autocomplete="off"/>
                            </div>
                            <div className={tools_classes.form_group}>
                                <div className={tools_classes.text_left}>Confirm Password</div>
                                <input type="password" onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword} id="cps" name='confirmPassword' placeholder={confirmPassword} autocomplete="off"/>
                            </div>
                        </div>
                        <div className={classes.bottom_buttons}>
                            <div className={tools_classes.success_msg}>{isSuccess && <ToastContainer/>}</div> 
                            <div className={tools_classes.error_msg}>{isError && <ToastContainer/>}</div> 
                            {popupLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                            <button onClick={remove_ps_input} className={classes.cancel_btn}>Cancel</button>
                            <button onClick={()=>{handleResetPassword(targetUserId)}} className={classes.green_btn}>Reset</button> 
                        </div>
                    </div>                         
                </PopUpCard>
            )}


            {showOfferPopup ? (
                <PopUpCard loading={loadingResults} toggle={()=>{setSelectedOffer(); setDisplayOfferDataPopup(false); setShowOfferPopup(false)}} header={`Offers - ${targetUserName}`}>
                {displayOfferDataPopup? (
                    <>{displayAllDataOfTargetOffer(selectedOffer, loadingResults, showOfferPopup, setShowOfferPopup, displayOfferDataPopup, displayOfferDataPopup, displayOfferDataPopup, setDisplayOfferDataPopup, setSelectedOffer, true)}</>

                ):(
                    <ResponsiveTable loadingResults={loadingResults}>
                        <thead><ReportHeaders header_names={offers_table_headers_limited}/></thead>
                        <tbody className={classes.table_data}>{renderLimitedOffers()}</tbody>
                    </ResponsiveTable>
                )}
                </PopUpCard>



















            // table div to display admin users
            ):(
                <div className={classes.main}>
                    {showUser?(
                        <div className={classes.main_middle}>
                            <div className={`${classes.pagination_search_div} ${classes.margin_bottom}`}>
                                <div className={classes.select_search_item_div}>
                                    <div className="heading">{editUser? "Edit User": "User Information"}</div>
                                    {!editUser && 
                                    <div className={tools_classes.btn_submit}>
                                        <img onClick={()=>{setEditUser(true)}} src={edit_user} alt="Edit User"/>
                                    </div>} 
                                    {loadingSearchResults && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}

                                </div>
                                <div className={classes.close_div}>
                                    <img className={classes.cursor_pointer} src={cross_gray_icon} alt="X" onClick={()=>{
                                        setShowUser(false) 
                                        setEditUser(false)
                                    }}/>
                                </div>
                            </div>
                            <div className={tools_classes.success_msg}>{isSuccess && <ToastContainer/>}</div> 
                            <div className={tools_classes.error_msg}>{isError && <ToastContainer/>}</div> 

                            <div className={classes.view_edit_div}> 
                                <div className={classes.each_view_edit_div}> 
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>User Id</div>
                                        <input disabled type="text" onChange={(e)=>setUserId(e.target.value)} value={userId} id="userId" name='userId' placeholder={userId} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Full Name</div>
                                        <input disabled={!editUser} type="text" onChange={(e)=>setProfileName(e.target.value)} value={profileName} id="fullName" name='name' placeholder={profileName} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Email</div>
                                        <input disabled={!editUser} type="text"  onChange={(e)=>setEmail(e.target.value)} value={email} id="email" name='email' placeholder={email} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Is Admin?</div>
                                        <input disabled={!editUser}  name='isAdmin' type='text' onChange={(e)=>setIsAdmin(e.target.value)} value={isAdmin} id="isAdmin" placeholder={isAdmin} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Balance</div>
                                        <input disabled={!editUser}  name='balance' type='text' onChange={(e)=>setBalance(e.target.value)} value={balance.toLocaleString()} id="balance" placeholder={balance.toLocaleString()} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Payment Requested</div>
                                        <input disabled={!editUser}  name='paymentRequested' type='text' onChange={(e)=>setPaymentRequested(e.target.value)} value={paymentRequested} id="paymentRequested" placeholder={paymentRequested} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Phone</div>
                                        <input disabled={!editUser} type="text"   onChange={(e)=>setPhone(e.target.value)} value={phone} id="phone" name='phone' placeholder={phone} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Telegram</div>
                                        <input disabled={!editUser} type="text"   onChange={(e)=>setTelegram(e.target.value)} value={telegram} id="telegram" name='telegram' placeholder={telegram} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Skype</div>
                                        <input disabled={!editUser} type="text" onChange={(e)=>setSkype(e.target.value)} value={skype} id="skype" name='skype' placeholder={skype} autocomplete="off"/>
                                    </div>
                                    {/* <div className={tools_classes.btn_submit_div}>
                                        <div className={tools_classes.btn_submit}>
                                            {editUser && <button onClick={()=>{}}>Save</button>}                                    
                                        </div> 
                                    </div>  */}
                                </div>


                                {/* <div className={`${tools_classes.contact_information_title} ${tools_classes.top_extra_margin}`}>
                                    <div className="heading">Company Information</div>
                                </div> */}
                                {/* <div className={tools_classes.line_password}></div> */}
                            
                                <div className={classes.each_view_edit_div}> 
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Tax ID</div>
                                        <input disabled={!editUser} name='name' type='text' onChange={(e)=>setTaxId(e.target.value)} value={taxId}  id="taxId" placeholder={taxId} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Address</div>
                                        <input disabled={!editUser}  name='name' type='text' onChange={(e)=>setAddress(e.target.value)} value={address} id="address" placeholder={address} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Address Line 2</div>
                                        <input disabled={!editUser}  name='name' type='text' onChange={(e)=>setAddress2(e.target.value)} value={address2}  id="address2" placeholder={address2} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>City</div>
                                        <input disabled={!editUser}  name='name' type='text' onChange={(e)=>setCity(e.target.value)} value={city}  id="city" placeholder={city} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>State</div>
                                        <input disabled={!editUser}  name='name' type='text' onChange={(e)=>setCountryState(e.target.value)} value={state} id="state" placeholder={state} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Country</div>
                                        <input disabled={!editUser} name='name' type='text' onChange={(e)=>setCountry(e.target.value)} value={country}  id="country" placeholder={country} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.form_group}>
                                        <div className={tools_classes.text_left}>Zip</div>
                                        <input disabled={!editUser} name='name' type='text' onChange={(e)=>setZip(e.target.value)} value={zip}  id="zip" placeholder={zip} autocomplete="off"/>
                                    </div>
                                    <div className={tools_classes.btn_submit_div}>
                                        <div className={tools_classes.btn_submit}>
                                            {editUser && <button onClick={handleEditUserData}>Save</button>}                                    
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className={classes.main_middle}> 
                            {/* search and paginatino sectino */}
                            <div className={`${classes.pagination_search_div} ${classes.margin_bottom}`}>
                                {displayEntriesDiv(width, perPage, setPerPage, setPaginationStart, setPageNumber)}

                                {loadingSearchResults && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                                <div className={classes.search_div}>
                                    <img src={search_gray_icon} alt="Search"/>
                                    <input placeholder='Search user...' type='text' onChange={(e)=> setSearchValue(e.target.value)} value={searchValue}/>
                                </div>
                            </div>

                            <div className={classes.table_div}>
                                {/* admin users data table section */}
                                <ResponsiveTable loadingResults={loadingResults}>
                                    <thead><ReportHeaders header_names={report_table_headers} /></thead>
                                    <tbody className={classes.table_data}>{renderReports()}</tbody>
                                </ResponsiveTable>
                                {Data && Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>}
                            </div>
                            

                            {/* paginatino section */}
                            <div className={`${classes.pagination_search_div} ${classes.margin_top}`}>
                                <div className={classes.pagination_info_div}>
                                    Showing {rowsSent} entries of {totalRows} entries
                                </div>
                                <div className={classes.pagination_div}>
                                    {/* {pageNumber}  */}
                                    {/* {typeof pageNumber} {pageNumber-1} {pageNumber+1} */}
                                    
                                    <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}} value={pageNumber-1} className={`${classes.each_pagination_item} ${classes.each_pagination_item_left_border}`}><img className={classes.no_pointer_event} src={arrow_left} alt="<"/></button>
                                    {Array.from(Array(totalPages), (each_item, index) => {
                                        return <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}} value={index+1} className={`${classes.each_pagination_item} ${classes.extra_padding} ${`${((index+1)===pageNumber) && classes.mark_page_number}`}`}>{index+1}</button>
                                    })}
                                    <button onClick={(e)=>{handlePagination(e, pageNumber, totalPages, setPageNumber, setPaginationStart)}} value={pageNumber+1}className={`${classes.each_pagination_item} ${classes.each_pagination_item_right_border}`}><img className={classes.no_pointer_event} src={arrow_right} alt=">"/></button>
                                </div>
                            </div>
                        </div>     
                    )}
                </div>
            )}
        </div>
        </>
    )
}

export default AdminUsers;
